import React from "react";

import { Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Founders = () => {
  return (
    <Page
      title="Учредительные документы Фонда"
      path="/fund/founders"
      parent="fund"
    >
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1UDQ5wHvHuG5NY-nMukIh6twJ2ThJXWXa/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Свидетельство ЦБ РФ
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1FVCGyVkkKk7eLgs8ON0qn0D_w8jXahlc/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Свидетельство ИФНС от 16.06.2010
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1G_BpHkv21M60RaxEMXnqVJV7kUrsocch/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Свидетельство ИФНС ИНН КПП
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/12_QBNUcckAxEEqdLyn1f5TKfTwO6qlca/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Свидетельство МИНЮСТ от 04.03.2020
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1Wk3E11oWej0ETPZN-GdPaEMtX28b32ss/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Устав Фонда от 01.02.2021
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1u5VCTVFmWFEF3hFvUT4-pkmKhDreos6r/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Предыдущая версия Устава Фонда от 02.03.2018
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/10DExVXfIqDz6YZ3_4HsnLA-gzTVKcnpY/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Свидетельство о членстве в СРО
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/18F5oYdDGgz-T81BxgtOeRLig_fsOo4YD/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Политика в отношении обработки персональных данных
        </StyledLink>
      </Paragraph1>
    </Page>
  );
};

export default Founders;
